import { WppActionButton, WppIconInfo, WppTypography } from '@platform-ui-kit/components-library-react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { TENANT_SUBDOMAIN_WPP } from 'constants/common'
import styles from 'layout/infoBanner/InfoBanner.module.scss'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataContext'
import { routesManager } from 'utils/routesManager'

export enum WppTenantProjectsRoutes {
  NESTLE = '/nestle',
  VOLKSWAGEN = '/volkswagen',
  BEIERSDORF = '/beiersdorf',
  SPOTIFY = '/spotify',
  MINDSHARE = '/mindshare',
  MSIX = '/mSix',
}

export const InfoBanner = () => {
  const { currentTenant } = useTenantAndUserData()
  const isWppTenant = currentTenant.subdomain === TENANT_SUBDOMAIN_WPP
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const isWppTenantProjectsRoute =
    pathname.includes(WppTenantProjectsRoutes.NESTLE) ||
    pathname.includes(WppTenantProjectsRoutes.VOLKSWAGEN) ||
    pathname.includes(WppTenantProjectsRoutes.BEIERSDORF) ||
    pathname.includes(WppTenantProjectsRoutes.SPOTIFY) ||
    pathname.includes(WppTenantProjectsRoutes.MINDSHARE) ||
    pathname.includes(WppTenantProjectsRoutes.MSIX)

  if (!isWppTenant || !isWppTenantProjectsRoute) {
    return null
  }

  return (
    <Flex justify="between" align="center" className={styles.infoBanner}>
      <Flex gap={12}>
        <WppIconInfo className={styles.iconInfo} />
        <WppTypography type="s-midi" className={styles.bannerText}>
          You are viewing a demo client workspace, created to showcase how the Open <br /> platform can be used to
          support client needs
        </WppTypography>
      </Flex>
      <WppActionButton variant="inverted" onClick={() => navigate(routesManager.home.root.url())}>
        Back to WPP workspace
      </WppActionButton>
    </Flex>
  )
}
