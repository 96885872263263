export const KNOWLEDGE_BASE_HOME_URL = 'https://knowledge-base.os.wpp.com/en/'
export const OS_STATUS_PAGE_URL = 'https://status-wppopen.wpp.com/'
export const WPP_OPEN_PAGE_URL = 'https://central.wppopen.com'

export const TENANT_NAME_EASY_JET = 'EasyJet'
export const TENANT_NAME_WPP_PITCH_OS = 'WPP Pitch.OS'
export const TENANT_SUBDOMAIN_WPP = 'wpp'
export const TENANT_SUBDOMAIN_WPP_PITCH_OS = 'wpppitchos'
export const TENANT_SUBDOMAIN_NESTLE = 'nestle'
export const TENANT_SUBDOMAIN_LVMH_APAC = 'lvmh-apac'
export const TENANT_SUBDOMAIN_AUDIBLE = 'audible'
export const TENANT_SUBDOMAIN_UNILEVER = 'unilever'
